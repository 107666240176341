<template>
  <el-row class="device-work">
    <el-col :span="8">
      <div class="chart" id="device-order-chart">

      </div>
      <div class="filter">
        <span class="filter-title" v-popover:popover>{{pagination.year}}-{{pagination.month}}<span v-show="!pagination.isMonth">-{{pagination.day}}</span> 期  <i class="el-icon-date"></i></span>
        <el-popover
          ref="popover"
          placement="right"
          trigger="hover">
          <el-date-picker
            size="mini"
            :format="dateFmt"
            v-model="date"
            @change="handleDataChange"
            :type="type"
            placeholder="选取日期">
          </el-date-picker>
        </el-popover>


      </div>
      <div class="device-order-sw">
        <el-radio-group size="small" v-model="pagination.isMonth" @change="handleChangeModel">
          <el-radio-button label="false">每日期刊</el-radio-button>
          <el-radio-button label="true">每月期刊</el-radio-button>
        </el-radio-group>
      </div>

    </el-col>
    <el-col :span="15">
      <div class="device-summary">
        <div class="device-make device-summary-item">
          <p class="device-summary-title">本期制作数</p>

          <div class="number">{{sum.total}}<span>(杯)</span></div>
        </div>
        <div class="device-money device-summary-item">
          <p class="device-summary-title">本期营业额</p>
          <div class="number">¥ {{sum.money/100|F1000}}<span>(元)</span></div>
        </div>
      </div>

      <div class="device-list">
        <el-table :data="devices" max-height="410">
          <empty slot="empty"></empty>
          <el-table-column label="设备编号" align="center" prop="deviceUuid"></el-table-column>
          <el-table-column label="设备名称" align="center" prop="deviceName" min-width="100"></el-table-column>
          <el-table-column label="制作杯数" align="center">
            <template slot-scope="scope">
                {{scope.row.payOrders + scope.row.notPayOrders}}
            </template>
          </el-table-column>
          <el-table-column label="营业额" align="center">
            <template slot-scope="scope">
              {{scope.row.payMoney / 100 | F1000}}
            </template>
          </el-table-column>

        </el-table>
      </div>
      <el-pagination
        @current-change="handleCurrentChange"
        class="pagination"
        :current-page="pagination.curPage"
        :page-size="pagination.limit"
        layout="total, prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>
    </el-col>

  </el-row>


</template>

<script>
  import echarts from 'echarts'
  import {mapGetters} from "vuex";

  import {orderPeriodicalUnion, orderPeriodicalList} from '@/api/collection/order'

  export default {
    name: '',
    computed: {
      ...mapGetters(['theme'])
    },
    components: {},
    mounted: function () {
      this.deviceOrderChart = echarts.init(document.getElementById('device-order-chart'))
      let vue = this
      vue.date = new Date()
      vue.changeTheme(vue.theme)
      this.$mq.Subcribe(`theme`, function (data) {
        vue.changeTheme(data)
      })

      let now = new Date()
      this.pagination.year = now.getFullYear()
      this.pagination.month = now.getMonth() + 1
      this.pagination.day = now.getDate()
      this.loadOrderPeriodical()
    },
    data: function () {
      return {
        date: '',
        devices: [],
        sum: {
          total: 0,
          money: 0,
        },
        deviceOrderOption: {
          color: ["#8d7fec", "#5085f2"],
          legend: {
            bottom: "0%",
            left: '30%',
            textStyle: {
              color: '#333'
            },
            data: [
              '支付订单',
              '非支付订单'],


            formatter: function (name) {
              return '' + name
            }
          },
          series: {
            type: 'pie',
            radius: '60%',
            center: ["55%", "42%"],
            avoidLabelOverlap: false,
            itemStyle: { //图形样式
              normal: {
                borderWidth: 6,
              },
            },
            label: {
              normal: {
                show: false,
                formatter: '{text|{b}}\n{c} ({d}%)',
                rich: {
                  text: {
                    color: "#333",
                    fontSize: 12,
                    align: 'center',
                    verticalAlign: 'middle',
                    padding: 8
                  },
                  value: {
                    color: "#0063f3",
                    fontSize: 12,
                    align: 'center',
                    verticalAlign: 'middle',
                  },
                }
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: 16,
                }
              }
            },
            data: [
              {name: '支付订单', value: 0},
              {name: '非支付订单', value: 0},
            ]
          }
        },
        deviceOrderChart: null,
        dateFmt: 'yyyy-MM',
        type: 'month',
        pagination: {
          total: 0,
          current: 0,
          start: 0,
          limit: 10,
          year: 0,
          month: 0,
          day: 0,
          isMonth: true,
        },
      }
    },
    methods: {
      handleCurrentChange(value) {
        this.pagination.curPage = value
        this.pagination.start = (value - 1) * this.pagination.limit

      },

      handleDataChange(now){
        this.pagination.year = now.getFullYear()
        this.pagination.month = now.getMonth() + 1
        this.pagination.day = now.getDate()
        this.loadOrderPeriodical()
      },

      handleChangeModel(value) {
        this.pagination.isMonth = value ==='true'
        if (value ==='true') {
          this.dateFmt = 'yyyy-MM'
          this.type = 'month'
        } else {
          this.dateFmt = 'yyyy-MM-dd'
          this.type = 'date'
        }
        this.loadOrderPeriodical()
      },

      changeSumInfo(sum) {
        this.deviceOrderOption.series.data[0].value = sum.payOrders
        this.deviceOrderOption.series.data[1].value = sum.notPayOrders
        this.deviceOrderChart.setOption(this.deviceOrderOption)
        this.sum.total = sum.payOrders + sum.notPayOrders
        this.sum.money = sum.payMoney
      },

      loadOrderPeriodical() {
        this.pagination.curPage = 1
        this.pagination.start = 0
        orderPeriodicalUnion(this.pagination).then(res => {
          this.devices = res.data.list || []
          this.pagination.total = parseInt(res.data.total)
          this.changeSumInfo(res.data.sum)
        })
      },

      changeTheme(data) {
        let vue = this
        if (data === `dark`) {
          vue.deviceOrderOption.legend.textStyle.color = '#AAAFBA'
          vue.deviceOrderOption.series.label.normal.rich.text.color = '#AAAFBA'
        } else {
          vue.deviceOrderOption.legend.textStyle.color = '#333'
          vue.deviceOrderOption.series.label.normal.rich.text.color = '#333'
        }

        vue.deviceOrderChart.setOption(vue.deviceOrderOption)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .device-work {
    min-height: 666px;
    .chart {
      width: 360px;
      height: 320px;
      margin-bottom: $padding;
    }

    .filter {
      text-align: center;

      .filter-title {
        font-size: 16px;
        @include set_font_color(#2a3542, $--font-color-dark);
      }
    }

    .device-order-sw {
      margin-top: $padding;
      text-align: center;
    }

    .device-summary {
      display: flex;
      width: 100%;

      .device-summary-item {
        flex: 1;
        margin: $padding;
        padding: $padding-8;
        height: 80px;
        border-radius: $border-radius;
        text-align: center;

        .device-summary-title {
          width: 88px;
          margin: 0 auto;
          color: white;
          font-size: 14px;
          font-weight: 500;
          border-bottom: 1px solid white;
        }

        .number {
          font-size: 22px;
          font-weight: 600;
          color: white;
          margin: $padding 0;

          span {
            font-size: 14px;
          }
        }
      }

      .device-make {
        background-color: #24d0a1;
      }

      .device-money {
        background-color: #fcb130;
      }
    }

    .el-table {
      font-size: 12px;
      @include set_font_color($--font-02-color, $--font-color-dark);
    }

    .pagination {
      margin-top: $padding;
      text-align: right;
    }
  }
</style>
